<template>
   <div>
     <nav class="w-full bg-primary text-white py-5 px-5 flex justify-between">
       <button class="flex flex-row gap-2 items-center" @click="$router.push('/wedding')">
         <svg width="36" height="36" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="135.196" height="135.196" rx="37.9054" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M100.611 37.7292H49.5507V52.6951H34.5848V68.5413H34.6112C34.9422 76.8169 38.3749 84.6853 44.254 90.5645C50.4452 96.7556 58.8422 100.234 67.5977 100.234C76.3533 100.234 84.7503 96.7556 90.9414 90.5645C97.0287 84.4772 100.493 76.2574 100.608 67.6609H100.611V37.7292ZM85.6448 52.6951V67.2208H85.5877C85.5877 71.992 83.6923 76.5678 80.3185 79.9416C76.9448 83.3153 72.369 85.2107 67.5977 85.2107C62.8265 85.2107 58.2507 83.3153 54.877 79.9416C51.5032 76.5678 49.6078 71.992 49.6078 67.2208H49.5507V52.6951H85.6448Z" fill="#1C7F56"/>
        </svg>
        <span class="font-bold text-xl">Bellioo</span>
       </button>

       <div class="hidden md:flex flex-row gap-3 items-center">
            <nuxt-link to="/wedding" class="p-3 rounded-md hover:bg-tertiary/30">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V4zM8 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H9a1 1 0 01-1-1V4zM15 3a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V4a1 1 0 00-1-1h-2z" />
                </svg>
            </nuxt-link>
            <nuxt-link v-if="is_admin" to="/users" class="p-3 rounded-md hover:bg-tertiary/30">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
            </nuxt-link>
            <nuxt-link v-if="is_admin" to="/songs" class="p-3 rounded-md hover:bg-tertiary/30">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
                </svg>
            </nuxt-link>
            <nuxt-link v-if="is_admin" to="/themes" class="p-3 rounded-md hover:bg-tertiary/30">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M6 3a1 1 0 011-1h.01a1 1 0 010 2H7a1 1 0 01-1-1zm2 3a1 1 0 00-2 0v1a2 2 0 00-2 2v1a2 2 0 00-2 2v.683a3.7 3.7 0 011.055.485 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0A3.7 3.7 0 0118 12.683V12a2 2 0 00-2-2V9a2 2 0 00-2-2V6a1 1 0 10-2 0v1h-1V6a1 1 0 10-2 0v1H8V6zm10 8.868a3.704 3.704 0 01-4.055-.036 1.704 1.704 0 00-1.89 0 3.704 3.704 0 01-4.11 0 1.704 1.704 0 00-1.89 0A3.704 3.704 0 012 14.868V17a1 1 0 001 1h14a1 1 0 001-1v-2.132zM9 3a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm3 0a1 1 0 011-1h.01a1 1 0 110 2H13a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
            </nuxt-link>
       </div>

        <button class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2" @click="logout">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span>Logout</span>
        </button>
    </nav>
    <div class="px-4 py-4">
        <Nuxt />
    </div>
   </div>
</template>

<script>
import { isAdmin } from '~/common/helper/validator'
export default {
    name: 'Navigation',
    middleware: 'auth',
    computed:{
        user(){
            return this.$auth.user
        },
        is_admin(){
            return isAdmin(this.user)
        }
    },
    methods:{
        async logout(){
            await this.$auth.logout()
        }
    }
}
</script>

<style scoped>
a.nuxt-link-exact-active {
    @apply bg-white text-primary transition ease-in-out delay-150 
}
</style>