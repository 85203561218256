<template>
  <div class="grid min-h-screen place-items-center">
    <div class="flex flex-col gap-5 p-5 bg-white rounded-md md:flex-row">
      <div
        v-if="error.statusCode === 404"
        class="font-bold text-blue-200 text-8xl"
      >
        404
      </div>
      <div
        v-if="error.statusCode === 500"
        class="font-bold text-red-200 text-8xl"
      >
        500
      </div>
      <div class="flex flex-col items-center justify-center text-gray-500">
        <span v-if="error.statusCode === 404">Oops ! Page not found.</span>
        <span v-if="error.statusCode === 500">Internal Server error.</span>
        <span
          >Return to
          <NuxtLink to="/wedding" class="font-bold">Home page</NuxtLink></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  // layout: 'error' // you can set a custom layout for the error page
}
</script>
