<template>
  <div>
    <nav
      id="navigation"
      class="fixed top-0 left-0 z-20 w-screen bg-white navbar h-20 shadow"
    >
     <div class="flex justify-center">
       <div class="rounded-full h-24 w-24 bg-white mt-3">
          <div class="w-24 h-24 flex flex-col items-center">
            <nuxt-img preload provider="static" format="webp" src="/themes/theme3/logo.png" loading="lazy" style="height: 42px;width:auto;transform: rotate(-30deg);"/>
            <span class="analogue text-xs">{{ wedding.husband_nickname }}</span>
            <span class="analogue text-xs">{{ wedding.wife_nickname }}</span>
          </div>
      </div>
     </div>
    </nav>
  <!-- cover -->
  <div id="cover" class="w-screen h-screen fixed main-bg z-30 lg:bg-bottom" style="background-color: #DED4CB" v-lazy-background :lazy-background="wedding.images[0] ? wedding.images[0].url : ''">
    <div class="absolute bottom-0 right-0 left-0 text-white flex flex-col z-20" :class="isGuestExists ? 'h-full justify-around' : 'h-full justify-center'">
      <div class="text-center">
         <h1 class="font-fair text-2xl lg:text-7xl mb-3">THE WEDDING OF</h1>
         <h1 class="analogue text-3xl lg:text-6xl">{{ wedding.wife_nickname }} <span class="playfair-italic">&</span> {{ wedding.husband_nickname }}</h1>
      </div>
      <div v-if="isGuestExists" class="items-center flex flex-col font-fair">
        <span class="mb-3 text-2xl lg:text-4xl font-semibold text-center px-3"> Hello, {{ wedding.guests[0].name }}</span>
        <span class="mb-5 text-lg lg:text-2xl font-fair">
              You are invited
        </span> 
        <button class="px-4 lg:px-4 py-3 lg:py-4 rounded-xl max-w-max text-lg lg:text-2xl text-black" style="background-color: #DED4CB" @click="openInvitation">Open Invitation</button>
      </div>
    </div>
  </div>
  <!-- cover end -->


  <div id="body" class="hidden relative">
      <div id="surah" class="py-20 text-center" style="background-color: #DED4CB" v-lazy-background lazy-background="~/static/themes/theme3/texture.png">
        <div class="mx-auto w-4/5">
            <h1 class="analogue text-xl mb-3">{{ wedding.surah }}</h1>
              <q class="analogue text-base text-white italic tracking-wider" v-text="wedding.surah_detail"></q>
        </div>
      </div>

      <div id="person" class="py-10 relative z-10 flex flex-col items-center">
          <nuxt-img preload provider="static" format="webp" src="/themes/theme3/backdrop-1.png" loading="lazy" class="absolute z-0 top-10 left-0"/>
          <nuxt-img preload provider="static" format="webp" src="/themes/theme3/backdrop-2.png" loading="lazy" class="absolute z-0 bottom-0 right-0"/>
          <h2>GROOM & BRIDE</h2>
          <div class="flex flex-col items-center lg:flex-row lg:w-2/5 lg:justify-between lg:mt-28">
              <nuxt-img preload format="webp" :src="wedding.husband_image.url" loading="lazy" class="h-44 w-32 lg:h-64 lg:w-52 bg-gray-500 my-10 bg-cover lg:flex" />
              <div class="flex flex-col items-center gap-1 font-fair">
                <span class="text-xl analogue">{{ wedding.husband ? wedding.husband : '-' }}</span>
                <span class="font-fair">son of</span>
                <span class="font-fair">Mr. {{ wedding.husband_father }}</span>
                <span class="playfair-italic">&</span>
                <span class="font-fair">Mrs. {{ wedding.husband_mother }}</span>
                <span id="border" class="h-7 my-3" style="background-color:#262627; width: 2px;"></span>
                <a v-if="wedding.husband_instagram && wedding.husband_instagram !== null && wedding.husband_instagram !== 'null'" :href="wedding.husband_instagram" class="btn-social font-fair" target="_blank">INSTAGRAM</a>
                <a v-if="wedding.husband_twitter && wedding.husband_twitter !== null && wedding.husband_twitter !== 'null'" class="btn-social font-fair" :href="wedding.husband_twitter" target="_blank">TWITTER</a>
              </div>
          </div>
          <div id="border-lg" class="bg-black self-center w-2/5 mt-10 lg:hidden" style="height:2px;"></div>
          <div class="flex flex-col items-center font-fair  lg:flex-row-reverse lg:w-2/5 lg:justify-between lg:mt-28">
              <nuxt-img preload format="webp" :src="wedding.wife_image.url" loading="lazy" class="h-44 w-32 lg:h-64 lg:w-52 bg-gray-500 my-10 bg-cover lg:flex" data-aos="fade-up" data-aos-duration="300"/>
              <div class="flex flex-col items-center gap-1">
                <span class="text-xl analogue" data-aos="fade-up" data-aos-duration="300">{{ wedding.wife ? wedding.wife : '-' }}</span>
                <span class="font-fair" data-aos="fade-up" data-aos-duration="300">daughter of</span>
                <span class="font-fair" data-aos="fade-up" data-aos-duration="300">Mr. {{ wedding.wife_father }}</span>
                <span class="playfair-italic" data-aos="fade-up" data-aos-duration="300">&</span>
                <span class="font-fair" data-aos="fade-up" data-aos-duration="300">Mrs. {{ wedding.wife_mother }}</span>
                <span id="border" class="h-7 my-3" style="background-color:#262627;width: 2px;" data-aos="fade-up" data-aos-duration="300"></span>
                <a v-if="wedding.wife_instagram && wedding.wife_instagram !== null && wedding.wife_instagram !== 'null'" class="btn-social font-fair" :href="wedding.wife_instagram" target="_blank" data-aos="fade-up" data-aos-duration="300">INSTAGRAM</a>
                <a v-if="wedding.wife_twitter && wedding.wife_twitter !== null && wedding.wife_twitter !== 'null'" class="btn-social font-fair" :href="wedding.wife_instagram" target="_blank" data-aos="fade-up" data-aos-duration="300">TWITTER</a>
              </div>
          </div>
      </div>

      <div v-if="wedding.theme && wedding.theme.setting.time_place" id="events" class="pt-10 pb-14" style="background-color: #DED4CB" v-lazy-background lazy-background="~/static/themes/theme3/texture.png">
          <h2 data-aos="fade-up" data-aos-duration="300">WEDDING CEREMONY</h2>

          <div class="my-10 flex flex-col lg:flex-row lg:w-2/5 justify-between lg:gap-24 mx-auto lg:mt-16">
            <div v-for="(event, index) in wedding.events" :key="index" class="flex flex-col items-center gap-3">
              <span class="text-2xl analogue" data-aos="fade-up" data-aos-duration="300"> {{ event.name }} </span>
              <span class="font-fair" data-aos="fade-up" data-aos-duration="300"> {{ $moment(event.date).format('dddd[,] DD MMMM YYYY') }} </span>
              <span class="text-sm font-fair" data-aos="fade-up" data-aos-duration="300"> {{ $moment(event.date).format('LT') }} {{ event.end_date ? '- ' +$moment(event.end_date).format('LT'): '' }} </span>
              <div class="flex flex-col gap-1 items-center font-fair" data-aos="fade-up" data-aos-duration="300">
                <span class="text-sm font-bold"> venue : </span>
                <p class="text-xs text-center" v-html="event.address_detail"></p>
              </div>
              <a v-if="event.google_maps_link" class="btn-maps font-fair lg:mt-10" :href="event.google_maps_link" target="_blank" data-aos="fade-up" data-aos-duration="300">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 1.13867C14.8869 1.13867 17.1761 2.08688 18.864 3.77471C20.5518 5.46254 21.5 7.75172 21.5 10.1387C21.5 13.8647 18.66 17.7687 13.1 21.9387C12.9269 22.0685 12.7164 22.1387 12.5 22.1387C12.2836 22.1387 12.0731 22.0685 11.9 21.9387C6.34 17.7687 3.5 13.8647 3.5 10.1387C3.5 7.75172 4.44821 5.46254 6.13604 3.77471C7.82387 2.08688 10.1131 1.13867 12.5 1.13867ZM12.5 7.13867C11.7044 7.13867 10.9413 7.45474 10.3787 8.01735C9.81607 8.57996 9.5 9.34302 9.5 10.1387C9.5 10.9343 9.81607 11.6974 10.3787 12.26C10.9413 12.8226 11.7044 13.1387 12.5 13.1387C13.2956 13.1387 14.0587 12.8226 14.6213 12.26C15.1839 11.6974 15.5 10.9343 15.5 10.1387C15.5 9.34302 15.1839 8.57996 14.6213 8.01735C14.0587 7.45474 13.2956 7.13867 12.5 7.13867Z" fill="#8E4F1C"/>
                </svg>
                <span>Get Location</span>
              </a>
              <div v-if="index < wedding.events.length - 1" id="border-lg" class="bg-black self-center w-3/5 my-10 lg:hidden" style="height:2px;" data-aos="fade-up" data-aos-duration="300"></div>
            </div>
          </div>

           <div v-if="wedding.theme && wedding.theme.setting.countdown" id="time" class="w-full lg:w-4/5 mx-auto flex flex-col justify-center items-center text-center z-10 font-fair" style="color: #314260;" data-aos="fade-up" data-aos-duration="500">
            <h1 class="text-xl lg:text-4xl mb-6 uppercase analogue" style="color:#525050;">Counting down to the day</h1>
            <div class="text-5xl grid grid-cols-3 gap-5 lg:gap-10 items-center">
              <div class="p-3 lg:p-10">
                <div class="text-xl lg:text-6xl mb-6">{{ countdownData.days }}</div>
                <div class="text-base lg:text-3xl">Hari</div>
              </div>
                <div class="py-2 lg:py-10 px-10 lg:px-20 border-black"  style="border-inline-width:2px;">
                <div class="text-xl lg:text-6xl mb-6">{{ countdownData.hours }}</div>
                <div class="text-base lg:text-3xl">Jam</div>
              </div>
                <div class="p-3 lg:p-10">
                <div class="text-xl lg:text-6xl mb-6">{{ countdownData.minutes }}</div>
                <div class="text-base lg:text-3xl">Menit</div>
              </div>
            </div>
          </div>
      </div>

      <div v-if="wedding.theme && wedding.theme.setting.rsvp" id="rsvp">
        <div v-if="wedding.guests[0]" class="mt-24 lg:mt-48 relative min-h-max overflow-hidden pb-5 lg:pb-10 font-fair">
            <div class="text-center mb-4 px-5 lg:px-0">
              <h2 class="mb-3" data-aos="fade-up" data-aos-duration="300">RSVP</h2>
              <p class="font-fair text-sm" style="color:#7A7A7A;" data-aos="fade-up" data-aos-duration="300">Please kindly help us prepare everything better 
                by confirming your attendance to our wedding event 
                with the following RSVP form:</p>
            </div>
            <rsvp-form :attendances="attendances" :guest="wedding.guests[0]" :wedding="wedding" class="pb-14 px-5 lg:px-0" :theme-code="wedding.theme.code" @onGetAttendance="getAttendances" />
        </div>
      </div>

      <div  v-if="wedding.theme && wedding.theme.setting.gallery" class="py-6 relative flex flex-col items-center justify-center" style="background-color: #DED4CB;">
        <h2 data-aos="fade-up" data-aos-duration="300">PREWEDDING GALLERY</h2>

         <div
          v-if="wedding.gallery"
          id="gallery"
          v-viewer="propsViewer"
          class="flex flex-row w-full gap-3 my-10 overflow-x-auto lg:justify-center lg:w-5/6 lg:flex-wrap pb-10"
        >
          <nuxt-img v-for="(image, i) in wedding.gallery" :id="`image-${i}`" :key="i" preload format="webp" :src="image.url" loading="lazy" class="rounded-sm cursor-pointer max-h-60 bg-gray-400"  data-aos="zoom-in" data-aos-duration="500" />
        </div>
      </div>

      <div v-if="wedding.theme && wedding.theme.setting.gift" id="gift" class="py-10 px-10 flex flex-col items-center">
          <h2 class="mb-10" data-aos="fade-up" data-aos-duration="300">WEDDING GIFT</h2>
          <p class="text-center font-fair" style="color:#7A7A7A" data-aos="fade-up" data-aos-duration="300">For family and friends who would like to send a gift. We would be glad to receive it. Tap the following buttons to send them to us:</p>

          <button class="btn-gift mt-4 constantia"  data-aos="fade-up" data-aos-duration="300" @click.prevent="openBank()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="#8E4F1C">
              <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
              <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
            </svg>
            <span>Wedding Gift</span>
          </button>

          <div v-if="wedding.gifts.length > 0"  id="bank-account">
             <div
                v-for="(gift, i) in wedding.gifts"
                :key="i"
                class="flex flex-col items-center my-3"
              >
                <p class="text-center capitalize p-5 rounded-md bg-opacity-10 bg-gray-400">
                  {{ gift.account_name }} an. {{ gift.name }} -
                  {{ gift.account_number }}
                </p>

                <button
                  class="btn-gift mt-4 constantia"
                  @click.prevent="copy(gift.account_number)"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="#8E4F1C">
                    <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                    <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                  </svg>
                  <span>Copy Rekening</span>
                </button>
              </div>
          </div>
      </div>

      <div v-if="wedding.theme && wedding.theme.setting.copyright" class="main-bg bg-white" v-lazy-background :lazy-background="wedding.images[1] ? wedding.images[1].url : ''">
          <div class="flex items-center justify-center bg-gradient-to-b from-white" style="min-height: 50vh">
              <div class="grid place-items-center w-4/5">
                <div class="flex flex-col items-center">
                    <h2 style="font-size:24px !important;">THANK YOU</h2>
                    <p class="my-5 text-center text-base font-fair" style="letter-spacing: 1.4px; line-height: 21px;">It is a pleasure and honor for us, if you are willing to attend and give us your blessing.</p>
                    
                    <nuxt-img preload provider="static" format="webp" src="/themes/theme3/logo.png" loading="lazy" style="height: 82px;width:auto;transform: rotate(-30deg);" />
                    <div class="text-xl">
                        <span class="analogue">{{ wedding.husband_nickname }}</span> 
                        <span class="playfair-italic">&</span> 
                        <span class="analogue">{{ wedding.wife_nickname }}</span>
                  </div>
                </div>
              </div>
          </div>
      </div>

        <footer
          id="footer"
          class="flex justify-center py-5 text-sm font-fair bg-white"
      >
            <a href="https://bellioo.com/id" target="_blank">
              Bellioo &#169; {{ $moment().format('YYYY') }}.
            </a>
        </footer>

      <div
          id="notif-box"
          class="hidden transform transition ease-in duration-150 fixed flex-row items-center gap-1 p-2 text-sm bg-white rounded-md shadow-md box left-5 bottom-5 z-20"
        >
          <div style="color: #bfa08b">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
              />
            </svg>
          </div>
          <p>Copied !</p>
        </div>
    </div>
    <audio-player :url="wedding.songs[0].url" :is-playing="isPlaying" color="#DED4CB" />

  </div>
</template>

<script>
import AOS from 'aos'
import { getCountDown } from '~/common/helper/date'
import rsvpForm from '~/components/common/rsvpForm.vue'
import audioPlayer from '~/components/common/audioPlayer.vue'
import 'aos/dist/aos.css' // You can also use <link> for styles
export default {
  name: 'ThemeTwo',
  components: { rsvpForm, audioPlayer },
  props: {
    wedding: {
      type: Object,
      require: true,
      default: () => { /* TODO document why this method 'default' is empty */ }
    }
  },
  data() {
    return {
      countdown: null,
      countdownData: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      attendances: [],
      isPlaying: false,
      propsViewer: {
        navbar: true,
        toolbar: true,
      },
      windowTop: 0
    }
  },
   computed:{
    isGuestExists(){
      return this.wedding?.guests && this.wedding?.guests.length > 0
    },
    infiniteGallery(){
      if(this.wedding.gallery.length > 0 && this.wedding.gallery.length <= 30){
          const infinity = this.wedding.gallery
          infinity.concat(this.wedding.gallery, this.wedding.gallery)
          return infinity.slice(0,29)
      }
      return this.wedding.gallery
    }
  },

  watch:{
  windowTop(newVal) {
    const element = document.getElementById('navigation')
    const find = element.classList.contains('open')
    if (newVal > 50 && !find) element.classList.toggle('open')
    else if (newVal < 50 && find) element.classList.toggle('open')
  }
  },
  beforeDestroy() {
    // delete interval
    if(this.countdown) clearInterval(this.countdown)
    window.removeEventListener('scroll', this.onScroll)
  },
  
  mounted(){

    window.addEventListener('scroll', this.onScroll)
    AOS.init({  duration: 500  });

    if(this.wedding.wedding_date){
      this.runCountDown()
    }

    this.getAttendances()

    this.$moment.updateLocale('en')
  },
  methods:{
     openInvitation(){
      
      const body = document.getElementById('body')
      body?.classList.remove('hidden')
      body?.scrollIntoView({ behavior: 'smooth' })

      AOS.refresh()

      this.isPlaying = true

      const cover = document.getElementById('cover')
      const addClass = ['-translate-y-full', 'transition', 'duration-1000']
      cover?.classList.add(...addClass)
      setInterval(() => cover?.classList.add('opacity-0'), 1000)
    },
    runCountDown(){
      this.countdown = setInterval(() => {
            const {days, minutes, hours, interval} = getCountDown(this.wedding?.events[0]?.date ?? this.wedding.wedding_date)

            this.countdownData = {
              days: days < 10 ? '0'+ days : days,
              hours: hours < 10 ? '0'+ hours  : hours,
              minutes: minutes < 10 ? '0'+ minutes  : minutes,
            }
            if(interval < 0){
              clearInterval(this.countdown)
            }
          }, 1000);
    },
    getAttendances() {
      const urlApi =`/api/v1/public/attendances?wedding_id=${this.wedding.id}`
      this.$axios.get(urlApi).then(({data}) =>{
        this.attendances = data.data
      }).catch(() => {
          //
      })
    },
    openBank() {
      const ba = document.getElementById('bank-account')?.classList
      ba?.toggle('open')
    },
    copy(text) {
        const notif = document.getElementById('notif-box')

        navigator.clipboard.writeText(text).then(
          () => {
            console.log('Async: Copying to clipboard was successful!')
            notif.classList.remove('hidden')
            notif.classList.add('flex')
            setTimeout(function () {
              notif.classList.remove('flex')
              notif.classList.add('hidden')
            }, 2000)
          },
          (err) => {
            console.error('Async: Could not copy text: ', err)
          }
      )
    },
    onScroll() {
      this.windowTop =
        window.top?.scrollY /* or: e.target.documentElement.scrollTop */
    }
  }
}
</script>


<style scoped>
.main-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

h2 {
  color: #525050;
  @apply text-xl text-center font-fair lg:text-3xl
}
h2::after, h2::before {
  content: '-';
  @apply mx-2 text-3xl
}

.btn-social {
  border: solid 1px #C08251;
  min-width: 11em;
  @apply px-10 py-3 uppercase text-black text-center max-w-fit
}

.btn-maps {
  background-color:#C08251;
  min-width: 11em;
  @apply flex flex-row justify-center gap-3 py-2 px-3 capitalize text-white text-center max-w-fit
}

.btn-gift {
  background-color: #DED4CB;
  @apply rounded-md px-2 py-3 flex flex-row gap-2
}

#bank-account {
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-in-out;
}

#bank-account.open {
  height: max-content;
}

.navbar {
  transform: translateY(-120px);
  transition: all 0.8s ease-in-out;
}

.navbar.open {
  transform: translateY(0px);
  max-width: 100vw;
}

.shadow {
  box-shadow: 0px 10px 30px 0px #3A3A3A1A;
}

@media (min-width: 1024px) { 
  .main-bg {
    background-position: 0% 65%;
  }
}
</style>